<template>
  <div class="list-con">
    <ul v-if="data && data.length" class="list back-fff flex-wrap flex-column justify-between">
      <li class="item border-r4 flex" v-for="(item,index) in data" :key="index">
        <el-image :src="item.thumbnail?$base+item.thumbnail:require('@/assets/images/default.png')"
          class="item-img border-r4"> </el-image>
        <div :class="['item-info', 'ml-20', {'flex-1':$isPc}, 'flex-column', $isPc?'justify-between':'justify-around']">
          <div class="justify-between">
            <h4 class="item-title ">
              <router-link :to="{path:'/details',query:{id:item.id}}" class="text-ellipsis-lines">{{item.title}}
              </router-link>
            </h4>
            <span v-if="$isPc" class="item-default">{{item.created_at|filterTime}}</span>
          </div>
          <p v-if="$isPc" class="item-info-text item-default text-ellipsis-lines">
            {{$settleHtml(item.introduction)}}
          </p>
          <p class="justify-between align-center">
            <span class="item-name align-center">
              <el-image :src="require('@/assets/images/list-icon.png')"></el-image>
              调研小组
            </span>
            <span>
              {{item.read_num}}浏览
              <template v-if="!$isPc">
                &nbsp;&nbsp;{{item.created_at|filterTime}}
              </template>
            </span>
          </p>
        </div>
      </li>
    </ul>
    <div v-else class="back-fff ptb-20 justify-center">
      <p>暂无数据</p>
    </div>
    <Page v-if="$isPc" v-bind="$attrs" v-on="$listeners" />
  </div>
</template>
<script>
import Page from '@/components/Page.vue'
export default {
  name: 'List',
  props: ['data'],
  components: {
    Page
  },
}
</script>
<style lang="scss" scoped>
.list {
  padding: 10px 35px;
}
.item {
  width: 100%;
  height: 135px;
  padding: 20px 0;
  border-bottom: 1px solid #cccccc;
  &:last-child {
    border-bottom: none;
  }
}
.item-img {
  width: 240px;
  height: 100%;
}
.item-info {
  width: 65%;
  .item-title {
    width: 88%;
    font-size: 20px;
    a {
      width: 100%;
    }
  }
  .item-info-text {
    margin: 12px 0 30px;
  }
  .item-name {
    .el-image {
      margin-right: 6px;
    }
  }
  .item-default {
    font-size: 16px;
    color: #999999;
    line-height: 24px;
  }
}
@media (max-width: 767px) {
  .list {
    padding: 0;
  }
  .item {
    width: 94.7%;
    height: 80px;
    padding: 20px 10px;
  }
  .item-img {
    flex: 1;
  }
  .item-info {
    margin-left: 10px;
    .item-title {
      width: 100%;
      min-height: 40px;
      font-size: 16px;
      line-height: 20px;
    }
    p {
      font-size: 14px;
      .item-name {
        .el-image {
          width: 18px;
          height: 18px;
          margin-right: 5px;
        }
      }
    }
    .item-info-text {
      margin: 0;
    }
    .item-default {
      font-size: 14px;
      line-height: 16px;
    }
  }
}
</style>