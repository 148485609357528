import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home/index.vue'
import { setItem, getItem } from '@/utils/storage'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // 关于IPBS
  {
    path: '/aboutIPBS',
    name: 'AboutIPBS',
    component: () => import('@/views/AboutIPBS/index.vue')
  },
  // 市场统计
  {
    path: '/market',
    name: 'Market',
    component: () => import('@/views/Market/index.vue')
  },
  // 市场统计详情
  {
    path: '/market-details',
    name: 'MarketDetails',
    component: () => import('@/views/Market/details.vue'),
    meta: { isDetails: true, detailsTitle: '项目详情' }
  },
  // 研报
  {
    path: '/report',
    name: 'Report',
    component: () => import('@/views/Report/index.vue')
  },
  // 政策
  {
    path: '/policy',
    name: 'Policy',
    component: () => import('@/views/Policy/index.vue')
  },
  // 关于我们
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import('@/views/AboutUs/index.vue')
  },
  // 文章详情
  {
    path: '/details',
    name: 'Details',
    component: () => import('@/views/Details/index.vue'),
    meta: { isDetails: true, detailsTitle: '文章详情' }
  },
  // 搜索结果列表
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/Search/index.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  // 设置网站搜索关键字
  let head = document.getElementsByTagName('head');
  let meta = document.createElement('meta');
  /** keywords-网页关键字 description-网站内容描述 */
  document.querySelector('meta[name="keywords"]').setAttribute('content', '知识产权证券化,知识产权资产证券化,知识产权证券化产品,知识产权证券化融资,知识产权证券化服务,知识产权证券化中金浩,专利证券化融资,专利资产证券化,商标证券化,中技所知识产权证券化,知识产权证券化交易,国内知识产权证券化,技术产权证券化,知识产权ABS,版权证券化,知识产权金融研究院')
  document.querySelector('meta[name="description"]').setAttribute('content', '')
  meta.content = to.meta.content;
  head[0].appendChild(meta)

  var u = navigator.userAgent;
  var IOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  // 修复iOS版微信分享HTML5 History兼容性问题
  if (IOS && !getItem('isIOS')) {
    setItem("isIOS", true)
    setItem("firstUrl", window.location.href.split('#')[0])
  }
  next()
})

// 避免重复点击路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
