<template>
  <div id="app">
    <Layout />
    <div v-show="!$isPc && isBackTop && $route.name!='Home'" class="back-top" @click="backTop">
      <i class="el-icon-top"></i>
    </div>
  </div>
</template>
<script>
import Layout from './views/Layout/index.vue'
export default {
  components: {
    Layout,
  },
  data () {
    return {
      scrollTop: "",
      isBackTop: false
    }
  },

  mounted () {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    // 显示回到顶部按钮
    handleScroll () {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.isBackTop = this.scrollTop > 200 ? true : false;
    },
    backTop () {
      var timer = setInterval(() => {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30)
    }
  },
  destroyed () {
    window.removeEventListener("scroll", this.handleScroll, true);
  }
}
</script>

<style lang="scss" scoped>
.back-top {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #2b62b2;
  position: fixed;
  bottom: 80px;
  right: 20px;
  text-align: center;
  line-height: 47px;
  i {
    font-weight: bold;
    color: #fff;
    font-size: 24px;
  }
}
</style>