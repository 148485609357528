
// 存
export const setItem = (key, value) => {
  window.sessionStorage.setItem(key, JSON.stringify(value));
}

// 取
export const getItem = (key) => {
  return JSON.parse(window.sessionStorage.getItem(key)) || null;
}

// 删
export const removeItem = (key) => {
  window.sessionStorage.removeItem(key);
}

// 清空
export const clear = (key) => {
  window.sessionStorage.clear();
}
