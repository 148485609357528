/** 数据总括 */
<template>
  <section class="data-blanket mb-30 pa-30 back-fff border-r8">
    <p class="justify-between">
      <span class="align-center">
        <el-image v-if="!$isPc" :src="require('@/assets/images/notice-icon.png')" style="margin-right:10px"></el-image>
        截止{{deadlineDate}}
      </span>
      <el-button v-if="isMore" type="text" @click="$router.push('/market')">
        查看更多
        <i class="el-icon-arrow-right el-icon--right"></i>
      </el-button>
    </p>
    <p v-show="$isPc || $route.name!='Home'" :class="['data-blanket-info',$isPc?'justify-between':'flex-wrap']">
      <span class="color-blue">
        全国知识产权证券化获批
        <b>
          <count-to :startVal='startVal' :endVal='approvedNum' :duration='duration'></count-to>
        </b>
        支(含储架) &nbsp;&nbsp; / &nbsp;&nbsp; 获批规模
        <b>
          <count-to :startVal='startVal' :endVal='approvedMoney' :duration='duration' :decimals="2"></count-to>
        </b>
        <b>亿</b>
        元
      </span>
      <span class="color-red">
        全国知识产权证券化发行
        <b>
          <count-to :startVal='startVal' :endVal='releaseNum' :duration='duration'></count-to>
        </b>
        支&nbsp;&nbsp; / &nbsp;&nbsp; 发行规模
        <b>
          <count-to :startVal='startVal' :endVal='releaseMoney' :duration='duration' :decimals="2"></count-to>
        </b>
        <b>亿</b>
        元
      </span>
    </p>
    <!-- 移动端显示 -->
    <el-carousel v-show="!$isPc && $route.name=='Home'" direction="vertical" indicator-position="none" height="45px"
      class="data-blanket-info">
      <el-carousel-item>
        <span class="color-blue">
          全国知识产权证券化获批
          <b>
            <count-to :startVal='startVal' :endVal='approvedNum' :duration='duration'></count-to>
          </b>
          支(含储架) &nbsp;&nbsp; / &nbsp;&nbsp; 获批规模
          <b>
            <count-to :startVal='startVal' :endVal='approvedMoney' :duration='duration' :decimals="2"></count-to>
          </b>
          <b>亿</b>
          元
        </span>
      </el-carousel-item>
      <el-carousel-item>
        <span class="color-red">
          全国知识产权证券化发行
          <b>
            <count-to :startVal='startVal' :endVal='releaseNum' :duration='duration'></count-to>
          </b>
          支&nbsp;&nbsp; / &nbsp;&nbsp; 发行规模
          <b>
            <count-to :startVal='startVal' :endVal='releaseMoney' :duration='duration' :decimals="2"></count-to>
          </b>
          <b>亿</b>
          元
        </span>
      </el-carousel-item>
    </el-carousel>
  </section>
</template>
<script>
import { getConfig } from '@/api/index'
import countTo from 'vue-count-to';
import { mapGetters } from 'vuex'
export default {
  props: {
    isMore: {
      typeof: Boolean,
      default: false
    }
  },
  components: { countTo },
  data () {
    return {
      deadlineDate:'',//截止日期
      duration: 3000,// 需要滚动的时间
      startVal: 0,// 初始值
      approvedNum: 0,//证券化获批数量
      approvedMoney: 0,//证券化获批规模
      releaseNum: 0,//证券化发型数量
      releaseMoney: 0,//证券化发型规模
    }
  },
  computed: {
    ...mapGetters([
      'config'
    ])
  },
  mounted(){
    if (this.config) {
      this.setData()
    }else{
      this.getConfig()
    }
  },
  methods:{
   async getConfig () {
      try {
        let { data } = await getConfig()
        this.$store.dispatch('setConfig', data)
        this.setData()
      } catch (error) { }
    },
    setData(){
      this.deadlineDate = this.config.find(i=>i.code==='project_explode_at').value
      this.approvedNum = this.config.find(i=>i.code==='project_approved').value
      this.approvedMoney = this.config.find(i=>i.code==='project_approved_scale').value
      this.releaseNum = this.config.find(i=>i.code==='project_release').value
      this.releaseMoney = this.config.find(i=>i.code==='project_release_scale').value
    }
  }
}
</script>
<style lang="scss" scoped>
// 数据总括
.data-blanket {
  position: relative;
  overflow: hidden;
  &.home-data {
    &::before {
      content: "";
      width: 60px;
      height: 60px;
      position: absolute;
      top: -30px;
      left: -30px;
      transform: rotate(45deg);
      background-color: #333333;
    }
  }
  p {
    color: #666666;
  }
  .data-blanket-info {
    margin-top: 35px;
    font-weight: bold;
    b {
      font-size: 38px;
    }
    .color-blue b {
      color: #2b62b2;
    }
    .color-red b {
      color: #b92604;
    }
  }
}

@media (max-width: 767px) {
  .data-blanket {
    padding: 10px;
    margin-bottom: 0;
    &.home-data {
      &::before {
        display: none;
      }
    }
    p {
      &:last-child {
        margin-top: 10px;
        font-weight: 400;
        line-height: 24px;
      }
      & > .el-button {
        font-size: 14px;
        i {
          margin-left: 1px;
        }
      }
    }
    .el-carousel__item {
      background-color: #fff;
    }
    .data-blanket-info {
      margin-top: 10px;
      span {
        margin-top: 10px;
        line-height: 22px;
      }
    }
  }
}
</style>