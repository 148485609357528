import request from "@/utils/request.js"

// 获取配置信息
export const getConfig = () => {
  return request({
    method: 'get',
    url: '/config',
  })
}

// 获取文章
export const getArticle = ({ page, keyword, type, page_size = 8 }) => {
  return request({
    method: 'post',
    url: '/article',
    data: {
      page,
      keyword,
      type,
      page_size
    }
  })
}

// 文章详情
export const getArticleinfo = (id) => {
  return request({
    method: 'post',
    url: '/articleinfo',
    data: {
      id
    }
  })
}

// 增加阅读量
export const addReading = (id) => {
  return request({
    method: 'post',
    url: '/addread',
    data: {
      id
    }
  })
}

// 获取市场统计table
export const getProjectlist = (page, keyword) => {
  return request({
    method: 'post',
    url: '/projectlist',
    data: {
      page,
      keyword,
      page_size: 10
    }
  })
}

// 微信分享
export const setShare = (url) => {
  return request({
    method: 'post',
    url: '/share',
    data: {
      url
    }
  })
}