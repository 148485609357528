/** 首页 */
<template>
  <div class="home content">
    <!-- banner图 -->
    <section class="home-banner flex mb-20">
      <div class="banner-left">
        <el-carousel class="border-r8" ref="slideCarousel" indicator-position="none" :height="bannerHeight + 'px'">
          <el-carousel-item v-for="(item,index) in lunbo" :key="index">
            <router-link :to="item.router_url">
              <el-image :src="item.img" fit="contain" ref='bannerHeight' @load="imgLoad"> </el-image>
            </router-link>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div v-if="$isPc" class="banner-right ml-20 flex-1 flex-column justify-between">
        <router-link :to="{path:'/details',query:{id:34}}" class="mb-20">
          <el-image :src="require('@/assets/images/home-banner1.png')" fit="fill"> </el-image>
        </router-link>
        <router-link :to="{path:'/details',query:{id:31}}">
          <el-image :src="require('@/assets/images/home-banner2.png')"> </el-image>
        </router-link>
      </div>
    </section>
    <!-- 数据总括 -->
    <DataBlanket class="home-data" :isMore="true" />
    <!-- 研报 -->
    <section class="home-yb mb-30">
      <div class="ipbs-title">
        <h4>研报</h4>
        <el-button type='text' @click="$router.push('/report')">
          查看更多<i class="el-icon-arrow-right el-icon--right"></i>
        </el-button>
      </div>
      <el-row class="yb-con" :gutter="$isPc?20:0">
        <el-col v-if="$isPc" :span="12" class="yb-img">
          <router-link class="border-r4" :to="{path:'/details',query:{id:ybDataHot.id}}">
            <el-image :src="ybDataHot.thumbnail?$base+ybDataHot.thumbnail:require('@/assets/images/default.png')"
              style="width:100%;height:100%"> </el-image>
            <div class="yb-hot align-center">
              <div class="yb-hot-title">行业热点</div>
              <p class="flex-1 text-ellipsis-lines">{{ybDataHot.title}}</p>
            </div>
          </router-link>
        </el-col>
        <el-col :span="$isPc?12:24">
          <ul class="yb-list flex-wrap flex-column justify-between">
            <li class="yb-item back-fff border-r4 flex" v-for="(item,index) in ybData" :key="index">
              <el-image :src="item.thumbnail?$base+item.thumbnail:require('@/assets/images/default.png')"
                class="item-img border-r4"> </el-image>
              <div :class="['yb-item-info', {'flex-1':$isPc}, 'flex-column', 'justify-between']">
                <h4 class="yb-item-title ">
                  <router-link :to="{path:'/details',query:{id:item.id}}" class="text-ellipsis">
                    {{item.title}}
                  </router-link>
                </h4>
                <p class="justify-between align-center">
                  <span class="yb-item-name align-center">
                    <el-image :src="require('@/assets/images/list-icon.png')"></el-image>
                    调研小组
                  </span>
                  <span>
                    {{item.read_num}}浏览
                    &nbsp;&nbsp;{{$isPc?'&nbsp;&nbsp;':''}}
                    {{item.created_at|filterTime}}
                  </span>
                </p>
              </div>
            </li>
          </ul>
        </el-col>
      </el-row>
    </section>
    <!-- 市场快讯 -->
    <section class="home-news mb-30">
      <div class="ipbs-title">
        <h4>市场快讯</h4>
        <el-button type='text' @click="$router.push('/market')">查看更多<i class="el-icon-arrow-right el-icon--right"></i>
        </el-button>
      </div>
      <div class="back-fff">
        <List :data="newsData" :count="newsCount" @page="changePage" />
      </div>
    </section>
    <!-- 知识产权证券化发行的里程碑 -->
    <section v-if="!$isPc" class="home-video">
      <div class="ipbs-title">
        <h4>知识产权证券化发行的里程碑</h4>
      </div>
      <IPBSVideo />
    </section>
    <!-- 关于我们 -->
    <section v-if="!$isPc" class="home-aboutUs mb-30">
      <div class="ipbs-title">
        <h4>关于我们</h4>
        <el-button type='text' @click="$router.push('/about-us')">查看更多<i class="el-icon-arrow-right el-icon--right"></i>
        </el-button>
      </div>
    </section>
  </div>
</template>

<script>
import List from '@/components/List.vue'
import DataBlanket from '@/components/DataBlanket.vue'
import IPBSVideo from '@/components/Video.vue'
import { getArticle } from '@/api/index'
export default {
  name: 'Home',
  components: {
    List,
    DataBlanket,
    IPBSVideo
  },
  data () {
    return {
      bannerHeight: '',
      lunbo: [
        { router_url: '/', img: require('../../assets/images/home-lb1.png') },
        { router_url: '/', img: require('../../assets/images/home-lb2.png') },
        { router_url: '/', img: require('../../assets/images/home-lb3.png') },
      ],
      ybDataHot: {},
      ybData: [],
      newsCount: '',
      newsData: []
    }
  },
  mounted () {
    this.getYbData('1')
    this.getNewsData('1')
  },
  methods: {
    async getYbData (page) {
      try {
        let { data, total } = await getArticle({ page, type: 'report', page_size: 5 })
        this.ybDataHot = data[0]
        this.ybData = data.slice(-4)
      } catch (error) { }

    },
    async getNewsData (page) {
      try {
        let { data, total } = await getArticle({ page, type: 'alerts', page_size: 5 })
        this.newsCount = total
        this.newsData = data
      } catch (error) { }

    },
    // 轮播自适应高度
    imgLoad () {
      this.$nextTick(() => {
        this.bannerHeight = this.$refs.bannerHeight[0].$el.offsetHeight
      })
    },
    changePage (page) {
      this.getNewsData(page)
    }
  }
}
</script>
<style lang="scss" scoped>
.el-image {
  border-radius: 8px;
  overflow: hidden;
}
.el-col {
  height: 100%;
  a {
    width: 100%;
    height: 100%;
  }
}
// banner图
/deep/ .home-banner {
  .banner-left {
    width: 67%;
    max-width: 936px;
    max-height: 400px;
    .el-carousel,
    .el-carousel__container {
      height: 100%;
    }
  }
}
// 研报
.yb-con {
  height: 388px;
  // 左侧
  .yb-img {
    a {
      position: relative;
      overflow: hidden;
      .yb-hot {
        width: 100%;
        height: 93px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: rgba(000, 000, 000, 0.7);
        .yb-hot-title {
          max-width: 48px;
          padding: 18px 24px;
          max-height: 100%;
          background-color: red;
          font-size: 24px;
          font-weight: bold;
          color: #fff;
          line-height: 29px;
        }
        p {
          padding: 0 30px;
          font-size: 24px;
          color: #fff;
          line-height: 30px;
        }
      }
    }
  }
  // 右侧列表
  .yb-list {
    width: 100%;
    height: 100%;
    .yb-item {
      width: 100%;
      height: 82px;
      .item-img {
        width: 146px;
      }
      .yb-item-info {
        width: 65%;
        padding: 12px 20px;
        p {
          color: #999999;
          font-size: 14px;
          .yb-item-name {
            color: #333333;
            .el-image {
              width: auto;
              height: auto;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
// 分页
/deep/ .page {
  justify-content: flex-end;
  padding: 0 35px 50px;
  .el-button {
    background-color: #ececec;
    &.is-disabled {
      background-color: #ececec;
    }
    &:hover,
    &:focus {
      background-color: #ececec;
    }
    &.active {
      background-color: #409eff;
    }
  }
}

@media (max-width: 767px) {
  .el-image {
    border-radius: 0;
  }
  /deep/ .home-banner {
    .banner-left {
      width: 100%;
    }
  }
  .home-yb,
  .home-news,
  .home-aboutUs {
    margin-bottom: 15px;
  }
  .yb-con {
    padding: 0 10px;
    // 右侧列表
    .yb-list {
      .yb-item {
        .item-img {
          flex: 1;
        }
        .yb-item-info {
          padding: 10px;
          p {
            font-size: 14px;
            .yb-item-name {
              .el-image {
                width: 18px;
                height: 18px;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
}
</style>