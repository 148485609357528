import {
  Button,
  Message,
  Container,
  Header,
  Main,
  Footer,
  Menu,
  MenuItem,
  Image,
  Input,
  Row,
  Col,
  Divider,
  Table,
  TableColumn,
  Carousel,
  CarouselItem,
  PageHeader,
  Popover
} from 'element-ui'
export default {
  install (Vue) {
    Vue.use(Button)
    Vue.use(Container)
    Vue.use(Header)
    Vue.use(Main)
    Vue.use(Footer)
    Vue.use(Menu)
    Vue.use(MenuItem)
    Vue.use(Image)
    Vue.use(Input)
    Vue.use(Row)
    Vue.use(Col)
    Vue.use(Divider)
    Vue.use(Table)
    Vue.use(TableColumn)
    Vue.use(Carousel)
    Vue.use(CarouselItem)
    Vue.use(PageHeader)
    Vue.use(Popover)
    //注意，如果使用了Message组件，并使用Vue.use(Message)的话。就掉坑里了。每次刷新页面都会弹出一个Message组件
    //Vue.use(Message)

    //直接使用this.$message
    Vue.prototype.$message = Message
  }
}