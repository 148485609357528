/** 移动端导航 */
<template>
  <div class="tab-bar">
    <el-menu :default-active="activeIndex" router class="el-menu-demo" mode="horizontal" background-color="#FFFFFF"
      text-color="#333333" active-text-color="#2B62B2">
      <el-menu-item v-for="(nav,index) in tabBarNavs" :key="index" :index="nav.route_url" :route="nav.route_url">
        <i :class="nav.icon"></i>
        <p>{{nav.name}}</p>
      </el-menu-item>
    </el-menu>
  </div>
</template>
<script>
import { setItem, getItem } from '@/utils/storage'
import { mapGetters } from 'vuex'
export default {
  name: 'TabBar',
  data () {
    return {
      activeIndex: getItem('router_url') || this.$route.path,
      tabBarNavs: [
        { name: '首页', route_url: '/', icon: 'el-icon-house' },
        { name: '市场统计', route_url: '/market', icon: 'el-icon-pie-chart' },
        { name: '研报', route_url: '/report', icon: 'el-icon-document' },
        { name: '政策', route_url: '/policy', icon: 'el-icon-reading' },
      ]
    }
  },
  computed: {
    ...mapGetters([
      'router_url'
    ])
  },
  watch: {
    $route (to, from) {
      if (!to.meta.isDetails) {
        setItem('router_url', to.path)
      }
      this.activeIndex = to.meta.isDetails ? getItem('router_url') : to.path
    },
    router_url (newVal) {
      this.activeIndex = newVal
    }
  },
}
</script>
<style lang="scss" scoped>
.tab-bar {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: #fff;
  padding: 10px 0;
  /deep/a {
    font-size: 14px;
  }
  .el-menu {
    border: none;
    display: flex;
    display: flex;
    justify-content: space-around;
    .el-menu-item {
      border: none;
      height: 100%;
      font-size: 14px;
      line-height: 1;
      i {
        font-size: 18px;
        margin: 0 0 5px;
      }
      &.is-active {
        border: none;
        background-color: transparent !important;
        font-weight: bold;
        i {
          font-weight: bold;
        }
      }
    }
  }
}
</style>