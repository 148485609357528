// 处理html字符
export const settleHtml = (string) => {
  if (string) {
    let start = string.indexOf('<');
    let end = string.indexOf('>')
    let data = string.slice(start, end + 1)
    string = string.replace(data, '')
    string = string.replace(' ', '')
    if (start != -1 && end != -1) {
      return settleHtml(string)
    } else {
      return string.replace(/\s*/g, "")
    }
  }
}