<template>
  <el-container direction="vertical">
    <!-- 头部 -->
    <el-header :height="$isPc?'100px':'60px'" class="back-fff">
      <Header />
    </el-header>
    <!-- 主体 -->
    <el-main>
      <keep-alive exclude="Details">
        <router-view />
      </keep-alive>
    </el-main>
    <!-- 尾部 -->
    <el-footer :height="$isPc?'auto':'57px'">
      <Footer v-if="$isPc" />
      <TabBar v-else />
    </el-footer>
  </el-container>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import TabBar from '@/components/TabBar.vue'
export default {
  name: 'Layout',
  components: {
    Header,
    Footer,
    TabBar
  },
}
</script>
<style lang="scss" scoped>
.el-container {
  min-height: 100%;
  background-color: #f3f3f3;
  .el-header {
    padding: 0;
  }
  .el-main {
    min-height: calc(100% - 160px);
    margin-top: 30px;
    overflow: initial;
  }
  .el-footer {
    background-color: #1d1d1d;
    color: #fff;
    text-align: center;
    margin-top: 65px;
    padding: 30px 0 20px;
  }
}
@media (max-width: 767px) {
  .el-container {
    background-color: #fff;
    .el-main {
      margin-top: 0;
    }
    .el-footer {
      background-color: #fff;
      margin-top: 0;
      padding: 10px 0;
    }
  }
}
</style>