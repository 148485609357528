/** 头部 */
<template>
  <div class="my-header back-fff">
    <div v-if="$isPc || !$route.meta.isDetails" class="content align-center">
      <router-link to="/">
        <el-image class="logo" :src="require($isPc?'@/assets/images/logo.png':'@/assets/images/wap-logo.png')"
          fit="cover"> </el-image>
      </router-link>
      <el-menu :default-active="activeIndex" router class="el-menu-demo" mode="horizontal" background-color="#FFFFFF"
        text-color="#333333" active-text-color="#2B62B2" v-if="$isPc">
        <el-menu-item v-for="(nav,index) in navs" :key="index" :index="nav.route_url" :route="nav.route_url">
          {{nav.name}}
        </el-menu-item>
      </el-menu>
      <div class="header-search flex-1">
        <el-input :class="{'inp-error':isSearchError}" v-model="searchVal" @input="isSearchError=false"
          @keyup.enter.native="searchBtn" placeholder="请输入关键词搜索" prefix-icon="el-icon-search">
          <el-button slot="suffix" @click="searchBtn">搜索</el-button>
        </el-input>
      </div>
    </div>
    <div v-else class="content align-center">
      <el-page-header class="el-icon-arrow-left" @back="goBack" :content="$route.meta.detailsTitle"> </el-page-header>
    </div>
  </div>
</template>
<script>
import { setItem, getItem } from '@/utils/storage'
import { mapGetters } from 'vuex'
export default {
  name: 'Header',
  data () {
    return {
      activeIndex: getItem('router_url') || this.$route.path,
      navs: [
        { name: '首页', route_url: '/' },
        { name: '关于IPBS', route_url: '/aboutIPBS' },
        { name: '市场统计', route_url: '/market' },
        { name: '研报', route_url: '/report' },
        { name: '政策', route_url: '/policy' },
        { name: '关于我们', route_url: '/about-us' },
      ],
      searchVal: '', // 搜索内容
      isSearchError: false,//是否展示搜索错误无样式
    }
  },
  computed: {
    ...mapGetters([
      'articleType',
      'router_url'
    ])
  },
  watch: {
    $route (to, from) {
      if (!to.meta.isDetails) {
        setItem('router_url', to.path)
      }
      this.activeIndex = to.meta.isDetails ? getItem('router_url') : to.path
    },
    router_url (newVal) {
      this.activeIndex = newVal
    }
  },
  methods: {
    // 搜索
    searchBtn () {
      if (this.searchVal) {
        this.$router.push({ path: '/search', query: { searchVal: this.searchVal } })
      } else {
        this.isSearchError = true
      }
    },
    // 返回上一页
    goBack () {
      if (window.history.length <= 2 && this.articleType) {
        this.$router.push(this.articleType)
      } else {
        this.$router.go(-1);//返回上一层
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.my-header {
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}
.logo {
  margin-top: 3px;
}
// 导航
.el-menu.el-menu--horizontal {
  border: none;
  margin-left: 4%;
  margin-top: -4px;
  .el-menu-item {
    height: 45px;
    line-height: 45px;
    font-size: 16px;
    padding: 0;
    margin: 0 15px;
    &:hover {
      background-color: transparent !important;
      color: #2b62b2 !important;
    }
    &.is-active {
      font-weight: bold;
      border-bottom-width: 3px;
    }
  }
}
// 搜索框
/deep/ .header-search {
  .el-input {
    float: right;
    max-width: 400px;
    .el-input__inner {
      background-color: #f3f3f3;
      padding-right: 56px;
    }
  }
  .el-button {
    color: #333;
    font-size: 16px;
    font-weight: bold;
    border: none;
    padding-right: 7px;
    background-color: transparent;
  }
}
@media (max-width: 767px) {
  .my-header {
    height: 60px;
    background-color: #2b62b2;
    // padding: 0 10px;
  }
  /deep/ .header-search {
    .el-input {
      // float: left;
      max-width: 88%;
      padding-right: 10px;
      .el-input__suffix {
        right: 10px;
      }
    }
  }
  /deep/ .el-page-header {
    width: 100%;
    padding: 0 10px;
    color: #fff;
    .el-page-header__left {
      margin-right: 0;
      &::after {
        display: none;
      }
      & > i {
        display: none;
      }
    }
    .el-page-header__content {
      flex: 1;
      font-size: 14px;
      color: #fff;
      text-align: center;
      margin-left: -52px;
    }
  }
}
</style>