import Vue from 'vue'
import Vuex from 'vuex'
import { setItem } from '@/utils/storage'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    config:null,
    articleType: '',
    router_url: '',
  },
  mutations: {
    SET_CONFIG (state, value) {
      state.config = value
    },
    SET_ARTICLE_TYPE (state, value) {
      if (value == '/alerts') {
        value = '/market'
      }
      state.articleType = value
      state.router_url = value
      setItem('router_url', value)
    }
  },
  actions: {
    // 项目配置
    setConfig (context, val) {
      context.commit('SET_CONFIG', val)
    },
    // 文章类型
    setArticleType (context, val) {
      context.commit('SET_ARTICLE_TYPE', val)
    },
  },
  getters: {
    config: state => state.config,
    articleType: state => state.articleType,
    router_url: state => state.articleType
  },
  modules: {
  }
})
