<template>
  <div v-if="pageNums.length>1" class="page flex">
    <el-button class="zpage-prev" icon="el-icon-arrow-left" :disabled="page===1" @click="pagePrev"></el-button>
    <ul class="page-list flex">
      <li class="page-item" v-for="(item,index) in pageNums" :key="index">
        <el-button :class="{'active':page===item}" @click="changePage(item)">{{item}}</el-button>
      </li>
    </ul>
    <el-button class="page-next" icon="el-icon-arrow-right" :disabled="page>=count" @click="pageNext"></el-button>
  </div>
</template>
<script>
export default {
  name: "Page",
  props: {
    count: {
      typeof: Number | String,
    },
    resetPage: {
      typeof: Boolean,
      default: false
    }
  },
  data () {
    return {
      page: 1,
      pageNums: []
    }
  },
  mounted () {
    this.setPageNum(this.count)
  },
  watch: {
    count (newVal) {
      this.setPageNum(newVal)
    },
    resetPage (newVal) {
      this.page = 1
    },
    // 页码切换
    page (newVal, oldVal) {
      this.$emit('page', newVal)
      let ind = this.pageNums.findIndex(item => item == newVal)
      if (newVal > oldVal) {
        if (newVal >= 3 && newVal <= this.count - 2) {
          for (let i = 0; i < ind - 1; i++) {
            let num = this.pageNums[this.pageNums.length - 1]
            this.pageNums.shift()
            this.pageNums.push(num + 1)
          }
        } else if (newVal === this.count - 1 && this.count !== this.pageNums[this.pageNums.length - 1]) {
          let num = this.pageNums[this.pageNums.length - 1]
          this.pageNums.shift()
          this.pageNums.push(num + 1)
        }
      } else {
        if (newVal >= 2 && newVal <= this.count - 3) {
          let num = this.pageNums[0]
          this.pageNums.unshift(num - 1)
          this.pageNums.pop()
        }
      }
    }
  },
  methods: {
    setPageNum (count) {
      this.pageNums = []
      for (let i = 0; i < count; i++) {
        if (i <= 3) {
          this.pageNums.push(i + 1)
        }
      }
    },
    changePage (page) {
      this.page = page
    },
    // 上一页
    pagePrev () {
      if (this.page > 1) {
        this.page -= 1
      }
    },
    // 下一页
    pageNext () {
      if (this.page < this.count) {
        this.page += 1
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.page {
  margin-top: 50px;
  justify-content: center;
}
.el-button {
  width: 40px;
  height: 36px;
  text-align: center;
  padding: 0;
  border: none;
  &,
  &.is-disabled {
    background-color: #fff;
  }
  &:hover {
    background-color: #fff;
    color: #2b62b2;
  }
  &:focus {
    background-color: #fff;
    color: #606266;
  }
  &.active {
    background-color: #2b62b2;
    color: #fff;
  }
}
.page-item {
  margin: 0 10px;
}
.zpage-prev {
  margin-right: 10px;
}
.page-next {
  margin-left: 10px;
}
</style>