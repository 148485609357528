import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import elementui from './plugins/elementUi'
import CountTo from "vue-count-to"//数字滚动
import { settleHtml } from './utils/index'

// 视频插件
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'

// 时间戳转化
import * as moment from '@/utils/filter'

import './assets/css/reset.scss'
import './assets/css/common.scss'
import './assets/css/common-media.scss'
import './assets/css/elCommon.scss'

Vue.config.productionTip = false
Vue.use(elementui)
Vue.use(VideoPlayer)
Vue.prototype.$settleHtml = settleHtml


Vue.component('count-to', CountTo)

// 图片地址
Vue.prototype.$base = process.env.VUE_APP_IMAGE_URL
Vue.prototype.$isPc = (/(iPhone|iPod|iOS|Android)/i.test(navigator.userAgent)) ? false : true

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
